module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vincent Wang","short_name":"VW","start_url":"/","background_color":"#6C63FF","theme_color":"#6C63FF","display":"standalone","icon":"src/assets/images/logovincentwang_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0561e5351b5a70434492d85df9653285"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://2f9b10fbc9b2406096d2d41f9d308d49@o543996.ingest.sentry.io/5665161","sampleRate":0.7},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
